/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled, { css } from 'styled-components';

// CSS
import 'styles/Partners.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'
import { BannerCustom } from 'components/Elements/Elements'
import BrandSlider from 'components/Brands/BrandSlider'

const Background = styled(BackgroundImage)`
  ${props => props.contain && css`
    background-size: contain;
    
    @media (max-width: 1199.98px) {
      height: 400px !important;
    }

    @media (max-width: 991.98px) {
      height: 300px !important;
    }

    @media (max-width: 575.98px) {
      height: 250px !important;
    }
  `};
`;

const Partners = ({data}) => {
  const { wordpressWpPartners: partners } = data

  return (
    <Layout>
      <SEO yoast={{ meta: partners.yoast_meta }} />
      <div className="kees-smit-partner">
        <h1 className="d-none">Kooloos Zon en Huis | Thuis in elk seizoen</h1>
        <Background className="kees-smit-partner-image" contain={partners.wordpress_id === 931} fluid={partners.acf.detail.image.localFile.childImageSharp.fluid} />
        <BannerCustom to="/contact" className="color-background-secondary" bannerText={partners.acf.detail.banner.text} bannerButtonText={partners.acf.detail.banner.button_text} />
        <div className="container py-5">
          <Content content={partners.acf.detail.content} />
        </div>
        <BannerCustom to="/contact" className="color-background-main" bannerText={partners.acf.detail.banner_second.text} bannerButtonText={partners.acf.detail.banner_second.button_text} />
        <BrandSlider title="Onze Merken" className="py-5 my-5" />
      </div>
    </Layout>
  )
}

export default Partners

export const pageQuery = graphql`
  fragment PartnersPostFields on wordpress__partners {
    id
    slug
  }
  query PartnersQuery($id: String!) {
    wordpressWpPartners(id: {eq: $id}) {
      wordpress_id
      title
      slug
      yoast_meta {
        content
        name
        property
      }
      acf {
        detail {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                fixed(width: 1024, toFormat: JPG) {
                  src
                }
              }
            }
          }
          content
          banner {
            text
            button_text
          }
          banner_second {
            text
            button_text
          }
        }
      }
    }
  }
`

